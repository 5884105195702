const getMobileOS = () => {
  const ua = navigator.userAgent;
  if (/android/i.test(ua)) {
    return "Android";
  } else if (
    /iPad|iPhone|iPod/.test(ua) ||
    (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
  ) {
    return "iOS";
  }

  return "Other";
};

const helloWorld = () => {
  console.log("Hello world 👋");

  const models = document.getElementsByClassName("demo-button");
  console.log(typeof models);
  if (!models || typeof models != "object") return;
  const modelsArray = Object.values(models);
  const myOS = getMobileOS();
  modelsArray.forEach((element) => {
    if (!Object.values(element.classList).includes(myOS)) {
      element.style.display = "none";
    }
  });
};

export default helloWorld;
